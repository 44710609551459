<template>
  <div class="content-wrapper">
    <div class="content-box">
      <el-form
        ref="passwordForm"
        :model="user"
        :rules="rules"
        @keyup.enter.native="checkForm"
      >
        <h1>Passwort vergessen?</h1>

        <el-form-item label="E-Mail-Adresse" prop="email">
          <el-input v-model.trim="user.email" type="email"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            class="fullwidth-button"
            :loading="loading"
            :disabled="inputDisabled"
            @click="checkForm"
          >
            E-Mail mit Anleitung anfordern
          </el-button>
        </el-form-item>
        <div class="links-container">
          <el-link @click="gotoLogin">Zurück zum Login</el-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import UserRepository from "@/repositories/user_repository.js"
import FormMixin from "@/mixins/form.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Passwort vergessen"
  },
  mixins: [FormMixin, ApiErrorHandlerMixin],
  data() {
    return {
      inputDisabled: false,
      user: {
        email: null
      },
      rules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte Ihre E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    gotoLogin() {
      this.$router.push({ name: "LoginPage" })
    },
    checkForm() {
      this.$refs["passwordForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.loading = true

      try {
        await UserRepository.requestPasswordInstructions(this.user.email)
        this.loading = false
        this.inputDisabled = true
        this.$message({
          message: `Die E-Mail mit der Anleitung zum Zurücksetzen des Passworts wurde versendet.`,
          type: "success",
          showClose: true,
          duration: 15000
        })
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.content-box {
  width: 400px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

h1 {
  text-align: center;
}

.fullwidth-button {
  width: 100%;
}

.links-container {
  display: flex;
  justify-content: center;
}
</style>
